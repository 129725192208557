import React from 'react';

import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { Redirect } from 'react-router6-redirect';

import App from './components/App';
import Home from './containers/home/Home';
import UnAuthorisedLogin from './containers/home/UnAuthorisedLogin';
import ForOrganisations from './containers/for-organisations/ForOrganisations';
import TheQACommons from './containers/theqacommons/TheQACommons';
import About from './containers/about/About';
import Partners from './containers/partners/Partners';
import Media from './containers/media/Media';
import Privacy from './containers/privacy/Privacy';
import Research from './containers/research/Research';
import Contact from './containers/contact/Contact';
import SignUp from './containers/auth/SignUp';
import Subscription from './containers/auth/Subscription';
import PaymentStatus from './containers/auth/PaymentStatus';
import MainLayout from './containers/layout/MainLayout';
import SkillBuilders from './containers/skill-builders/SkillBuilders';
import SkillBuilder from './containers/skill-builders/SkillBuilder';
import SkillBuildersList from './containers/skill-builders/SkillBuildersList';
import SkillBuilderEssentials from './containers/skill-builders/SkillBuildersEssentials';
import Discussions from './containers/skill-builders/Discussions';
import ClaimBadge from './containers/skill-builders/ClaimBadge';
import ClaimBadgeSurvey from './containers/skill-builders/ClaimBadgeSurvey';
import SkillBuilderAdmin from './containers/skill-builders/SkillBuilderAdmin';
import AdminHome from './components/skill-builders/admin/AdminHome';
import Unit from './containers/skill-builders/Unit';
import Profile from './containers/profile/Profile';
import ProfileWrapper from './containers/profile/ProfileWrapper';
import SkillBadgeWrapper from './containers/skill-badges/SkillBadgeWrapper';
import SkillBadgeUnit from './containers/skill-badges/SkillBadgeUnit';
import SkillUnit from './containers/skill-badges/SkillUnit';

import Dashboard from './containers/dashboard/Dashboard';
import HomePage from './containers/dashboard/HomePage';
import ActivityFeed from './containers/dashboard/ActivityFeed';

import Followings from './containers/profile/connects/Followings';
import Followers from './containers/profile/connects/Followers';
import RequireAuth from './containers/auth/RequireAuth';
import Settings from './containers/settings/Settings';

import AdminJobCenterWrapper from './containers/jobs/AdminJobCenterWrapper';
import ResumeContainer from './containers/resume/ResumeContainer';
import CreateResume from './containers/resume/CreateResume';

import LevelPage from './containers/profile/connects/LevelPage';
import Connect from './containers/connect/Connect';
import Terms from './containers/terms/Terms';
import NotFound from './components/notfound/NotFound';
import MessagesWrapper from './containers/messages/MessagesWrapper';
import MessageDetailsHandler from './containers/messages/MessageDetailsHandler';
import EndorsementRequest from './containers/skill-badges/EndorsementRequest';

import ManageOrganizations from './containers/admin/ManageOrganizations';
import ManageAccessRequests from './containers/admin/ManageAccessRequests';
import Statistics from './containers/admin/Statistics';

import AdminPageWrapper from './containers/admin/AdminPageWrapper';
import EducatorPageWrapper from './containers/admin/educators/EducatorPageWrapper';

import AdminDashboard from './containers/admin/AdminDashboard';
import EducatorDashboard from './containers/admin/educators/EducatorDashboard';

import ManageGroups from './containers/admin/ManageGroups';
import ManageIRs from './containers/admin/ManageIRs';
import EducatorManageGroups from './containers/admin/educators/EducatorManageGroups';
import ResourceCenter from './containers/admin/ResourceCenter';
import OrganizationAdmins from './containers/admin/OrganizationAdmins';

import CreateGroups from './containers/admin/CreateGroups';
import AdminTools from './containers/admin/AdminTools';

import ToolboxWrapper from './containers/toolbox/ToolboxWrapper';
import ToolboxContainer from './containers/toolbox/ToolboxContainer';
import VideoDetails from './containers/toolbox/interview-videos/VideoDetails';
import GlobalCompetenceModules from './containers/toolbox/GlobalCompetenceModules';
import GlobalCompetenceSurvey from './containers/toolbox/GlobalCompetenceSurvey';
import GlobalCompetenceSurveySuccess from './components/toolbox/GlobalCompetenceSurveySuccess';
import InterviewPrepVideos from './containers/toolbox/InterviewPrepVideos';
import FreeCareerResources from './containers/toolbox/FreeCareerResources';
import ModuleDetails from './containers/toolbox/ModuleDetails';

import Invitation from './containers/invitation/Invitation';
import SkillBadgeCriteria from './containers/skill-badges/SkillBadgeCriteria';
import PortfolioWrapper from './containers/profile/visiting/PortfolioWrapper';
import WebpageSkillBadges from './containers/profile/visiting/WebpageSkillBadges';
import WebpagePortfolio from './containers/profile/visiting/WebpagePortfolio';
import PortfolioDetails from './containers/profile/visiting/PortfolioDetails';
import SearchWrapper from './containers/search/SearchWrapper';
import CpPeopleSearch from './containers/search/CpPeopleSearch';
import CpPostSearchResults from './containers/search/CpPostSearchResults';
import CpSkillBadgesSearch from './containers/search/CpSkillBadgesSearch';
import CpPortfolioSearchResults from './containers/search/CpPortfolioSearchResults';
import Checkout from './containers/checkout/Checkout';
import GroupInvitation from './containers/invitation/GroupInvitation';
import OrganizationInitialSetup from './containers/admin/OrganizationInitialSetup';
import LoginPage from './containers/auth/LoginPage';
import SelfRegister from './containers/auth/SelfRegister';
import IAFSelfRegister from './containers/auth/IAFSelfRegister';
import EducatorSignUp from './containers/auth/EducatorSignUp';
import ManageInvites from './containers/admin/ManageInvites';

import JobsWrapper from './containers/jobs/JobsWrapper';
import IndeedJobs from './containers/jobs/IndeedJobs';
import JobBoard from './containers/jobs/JobBoard';
import SavedJobUnit from './containers/jobs/SavedJobUnit';
import JobMatch from './containers/jobs/JobMatch';

import FeedbackWrapper from './containers/feedback/FeedbackWrapper';
import Invite from './containers/invite-pages/Invite';
import MySkills from './containers/skills/SkillsWrapper';
import GiveFeedbackWrapper from './containers/feedback/GiveFeedbackWrapper';
import GiveFeedbackWrapperAdmin from './containers/feedback/GiveFeedbackWrapperAdmin';
import MyFeedbackWrapper from './containers/my-feedback/MyFeedbackWrapper';
import IRFeedback from './containers/ir-feedback/IRFeedback';
import ReceiveLinkedInInvite from './containers/receive-linkedin-invite/ReceiveLinkedInInvite';

import ReviseTestimonial from './containers/revise-testimonial/ReviseTestimonial';

import TalentDevPro from './containers/talent-dev-pro/TalentDevPro';
import SkillsToCareersCoalition from './containers/skills-to-careers-coalition/SkillsToCareersCoalition';
import TalentDevOrg from './containers/talent-dev-org/TalentDevOrg';
import Toolkit from './containers/toolkit/Toolkit';

export default function configRoutes() {
  return (
    <App>
      <Routes>
        <Route path="/" element={<MainLayout/>}>
          <Route index element={<Home />} />

          <Route path="talent-development-professionals" element={<TalentDevPro />}/>
          <Route
            path="skills-to-careers-coalition"
            element={<Navigate replace to="/skills-to-careers-initiative"/>}/>
          <Route path="skills-to-careers-initiative" element={<SkillsToCareersCoalition />}/>
          <Route
            path="institutions"
            element={<Navigate replace to="/talent-development-organizations"/>}/>
          <Route path="talent-development-organizations" element={<ForOrganisations />}/>
          <Route path="industry-mentor" element={<TalentDevOrg />}/>
          <Route path="toolkit" element={<Toolkit />}/>

          <Route path="about" element={<About />} />
          <Route path="partners" element={<Partners />} />
          <Route path="media" element={<Media />} />
          <Route path="research" element={<Research />} />
          <Route path="contact" element={<Contact />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="endorse-request/:id/:resourceType" element={<EndorsementRequest />} />
          <Route path="skillbadge-criteria" element={<SkillBadgeCriteria />} />
          <Route path="group" element={<SelfRegister />} />
          <Route path="IAF" element={<IAFSelfRegister />} />
          <Route path="educator-signup" element={<EducatorSignUp />} />
          <Route path="acte-educator-signup" element={<EducatorSignUp />} />

          <Route path="auth/sign-up" element={<SignUp />}/>
          <Route path="auth/subscription" element={<Subscription />}/>
          <Route path="auth/success" element={<PaymentStatus />}/>

          <Route path="auth/login" element={<LoginPage />} />

          <Route path="skill-builders" element={<SkillBuilders />}>
            <Route path="/" element={<SkillBuildersList />} />
            <Route path=":slug" element={<SkillBuilder />} />
            <Route
              path="essentials/:slug"
              element={<SkillBuilderEssentials />} />
            <Route path="discussions/:slug" element={<Discussions />} />
            <Route path="discussions/:slug/:postId" element={<Discussions />} />
            <Route path="claim-badge/:slug" element={<ClaimBadge />} />
            <Route
              path="claim-badge-survey/:slug"
              element={<ClaimBadgeSurvey />} />
          </Route>

          <Route
            path="skill-badges/badge-details/:slug"
            element={<SkillBadgeUnit />} />
          <Route path="skill-evidence/:slug" element={<SkillUnit />} />
          <Route path="skill-badges/:slug" element={<WebpageSkillBadges />} />
          <Route path="portfolio/:slug" element={<WebpagePortfolio />} />
          <Route
            path="portfolio/:slug/portfolio-details/:file"
            element={<PortfolioDetails />} />

          <RequireAuth path="/" element={<Dashboard />}>
            <Route path="home" element={<Outlet />}>
              <Route path="/" element={<HomePage />} />
            </Route>
            <Route path="activity-feed" element={<Navigate replace to="/community" />} />
          </RequireAuth>

          <RequireAuth path="checkout" element={<Outlet />}>
            <Route path="/" element={<Checkout />} />
          </RequireAuth>

          <RequireAuth path="settings" element={<Outlet />}>
            <Route path="/" element={<Settings />} />
          </RequireAuth>

          <RequireAuth path="educator/groups" element={<EducatorPageWrapper />}>
            <Route path="/" element={<EducatorDashboard />} />
            <Route path="manage" element={<EducatorManageGroups />} />
            <Route path="create" element={<CreateGroups />} />
            <Route path="resource-center" element={<ResourceCenter />} />
          </RequireAuth>

          <RequireAuth path="organization/admin" element={<AdminPageWrapper />}>
            <Route path="/" element={<AdminDashboard />} />
            <Route path="manage" element={<OrganizationAdmins />} />
            <Route path="manage-groups" element={<ManageGroups />} />
            <Route path="manage-irs" element={<ManageIRs />} />
            <Route path="job-sourcer-tool" element={<JobMatch />} />
            <Route path="resource-center" element={<ResourceCenter />} />
            <Route path=":organizationId" element={<CreateGroups />} />
          </RequireAuth>

          <RequireAuth path="toolbox" element={<JobsWrapper />}>
            <Route
              path="interview-prep-videos/:slug"
              element={<VideoDetails />} />
          </RequireAuth>

          <RequireAuth path="job-center" element={<JobsWrapper />}>
            <Route path="/" element={<IndeedJobs />} />

            <Route
              path="my-jobs"
              element={<Navigate replace to="/job-center/job-tracker" />} />

            <Route path="job-tracker" element={<JobBoard />} />
            <Route path="my-jobs/:slug" element={<SavedJobUnit />} />

            <Route path="resume-builder" element={<ResumeContainer />} />
            <Route path="resume-builder/create" element={<CreateResume />} />

            <Route
              path="interview-videos"
              element={<InterviewPrepVideos />} />
          </RequireAuth>

          <RequireAuth path="/" element={<AdminJobCenterWrapper />}>
            <Route
              path="job-center/resume-builder/:userId"
              element={<ResumeContainer />} />

            <Route
              path="job-center/job-tracker/:userId"
              element={<JobBoard />} />

            <Route
              path="job-center/job-details/:slug"
              element={<SavedJobUnit />} />
          </RequireAuth>

          <RequireAuth path="admin" element={<SkillBuilders />}>
            <Route path="tools" element={<AdminTools />} />

            <Route path="skill-builders" element={<SkillBuilderAdmin />}>
              <Route path="/" element={<AdminHome />} />
              <Route path="unit" element={<Unit />} />
              <Route path="unit/:id" element={<Unit />} />
              <Route path=":slug" element={<SkillBuilderEssentials />} />
            </Route>

            <Route
              path="manage-organizations"
              element={<ManageOrganizations />} />

            <Route
              path="manage-access-requests"
              element={<ManageAccessRequests />} />

            <Route
              path="statistics"
              element={<Statistics />} />

            <Route path="manage-invites/:id" element={<ManageInvites />} />
          </RequireAuth>

          <RequireAuth path="skill-badges" element={<Outlet />}>
            <Route path="/" element={<SkillBadgeWrapper />} />
          </RequireAuth>

          <RequireAuth path="portfolio" element={<Outlet />}>
            <Route path="/" element={<PortfolioWrapper />} />
            <Route path="/user/:userId" element={<PortfolioWrapper />} />
          </RequireAuth>

          <RequireAuth path="resources" element={<ToolboxWrapper />}>
            <Route path="/" element={<FreeCareerResources />} />
          </RequireAuth>
          <RequireAuth path="toolbox" element={<ToolboxWrapper />}>
            <Route path="/" element={<ToolboxContainer />} />
            <Route
              path="resume"
              element={<Navigate replace to="/job-center/resume-builder" />} />
            <Route
              path="resume/create"
              element={<Navigate replace to="/job-center/resume-builder/create" />} />
            <Route
              path="interview-prep-videos"
              element={<Navigate replace to="/job-center/interview-videos" />} />
            <Route
              path="free-career-resources"
              element={<Navigate replace to="/resources" />} />
          </RequireAuth>

          <Route path="resources" element={<ToolboxWrapper/>}>
            <Route
              path="global-competence-modules"
              element={<GlobalCompetenceModules/>}/>
            <Route
              path="global-competence-survey"
              element={<GlobalCompetenceSurvey/>}/>
            <Route
              path="global-competence-survey-success"
              element={<GlobalCompetenceSurveySuccess/>}/>
            <Route
              path="global-competence-modules/:slug"
              element={<ModuleDetails/>}/>
          </Route>

          <Route element={<ToolboxWrapper/>}>
            <Route
              path="/toolbox/global-competence-modules"
              element={<Navigate replace to="/resources/global-competence-modules"/>}/>
            <Route
              path="/toolbox/global-competence-survey"
              element={<Navigate replace to="/resources/global-competence-survey"/>}/>
            <Route
              path="/toolbox/global-competence-survey-success"
              element={<Navigate replace to="/resources/global-competence-survey-success"/>}/>
            <Route
              path="/toolbox/global-competence-modules/:slug"
              element={<Redirect to="/resources/global-competence-modules/:slug"/>}/>
          </Route>

          <RequireAuth path="connect" element={<Outlet />}>
            <Route path="connect" element={<Connect />} />
          </RequireAuth>

          <RequireAuth path="messages" element={<MessagesWrapper />}>
            <Route path=":slug" element={<MessageDetailsHandler />} />
          </RequireAuth>

          <Route path="cp/:slug" element={<ProfileWrapper />}>
            <Route path="/" element={<Profile />} />
            <Route path="level-page" element={<LevelPage />} />
            <Route path="following" element={<Followings />} />
            <Route path="followers" element={<Followers />} />
            <Route path="preview" element={<Profile />} />
            <Route path="portfolio/:slug" element={<Profile />} />
          </Route>

          <Route
            path="auth/new-password/*"
            element={<OrganizationInitialSetup />} />
          <Route
            path="auth/invitation-password/*"
            element={<OrganizationInitialSetup />} />
          <Route path="auth/reset-password/*" element={<Home />} />

          <Route path="auth/signup/*" element={<Invitation />} />
          <Route
            path="auth/group-admin-invite/*"
            element={<GroupInvitation />} />

          <RequireAuth path="search" element={<SearchWrapper />}>
            <Route path="/" element={<CpPeopleSearch />} />
            <Route path="people" element={<CpPeopleSearch />} />
            <Route path="files" element={<CpPortfolioSearchResults />} />
            <Route path="posts" element={<CpPostSearchResults />} />
            <Route path="badges" element={<CpSkillBadgesSearch />} />
          </RequireAuth>

          <RequireAuth path="feedback" element={<Outlet />}>
            <Route path="/" element={<FeedbackWrapper />} />
          </RequireAuth>

          <Route path="givefeedback/:feedbackRequestID" element={<GiveFeedbackWrapper />} />

          <Route path="admingivefeedback/:type/:rquestId" element={<GiveFeedbackWrapperAdmin />} />

          <RequireAuth path="myskills" element={<Outlet />}>
            <Route path="/:userId" element={<MySkills />} />
            <Route path="/" element={<MySkills />} />
          </RequireAuth>

          <Route path="invite/:token" element={<Invite />} />

          <RequireAuth path="revise-testimonial" element={<Outlet />}>
            <Route path=":testimonialId" element={<ReviseTestimonial />} />
          </RequireAuth>

          <Route
            path="receive-linkedin-response"
            element={<ReceiveLinkedInInvite />} />
          <RequireAuth path="myfeedback" element={<Outlet />}>
            <Route path="/" element={<MyFeedbackWrapper />} />
            <Route path="/:requestType" element={<MyFeedbackWrapper />} />
          </RequireAuth>
          <RequireAuth path="irfeedback" element={<Outlet />}>
            <Route path="/" element={<IRFeedback />} />
          </RequireAuth>
          <RequireAuth path="community" element={<Outlet />}>
            <Route path="/" element={<ActivityFeed />} />
            <Route path=":postId" element={<ActivityFeed />} />
          </RequireAuth>
          <Route path="reenter-credentials" element={<UnAuthorisedLogin />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="theqacommons" element={<TheQACommons/>}/>
        <Route
          path="career-education"
          element={<Navigate replace to="/talent-development-organizations"/>}/>
        <Route
          path="for-organizations"
          element={<Navigate replace to="/talent-development-organizations"/>}/>
      </Routes>
    </App>
  );
}
